import React, { useEffect, useRef, useState } from 'react'
import { SuccessModal, PaymentModal, ErrorModal } from '../../components/Modal'
import ViewRecoveryItemStyle from './ViewRecoveryItemStyle'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../components/Button'
import Layout from '../../components/Layout'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import api, { customFuncsApi, logsUrl } from '../../api'
import { AiOutlineSend } from 'react-icons/ai'
import { isEmpty } from 'lodash/lang'
import { roundCurrency } from '../../utils'
import moment from 'moment'
import CloseIcon from '../../assets/icons/close'
import ClipLoader from 'react-spinners/ClipLoader'
import numberWithSpaces from '../../helpers/numberWithSpaces'
import 'moment-timezone'
import { COMPANY_NAME } from '../../config'

const ViewRecoveryItem = () => {
	const { getMe } = useSelector((state) => state.main)

	const navigate = useNavigate()
	const location = useLocation()

	const successRef = useRef()
	const errorRef = useRef()
	const paymentRef = useRef()
	const commentRef = useRef(null)

	const executer = get(location, 'state.executer', [])
	const DocEntry = get(location, 'state.data.DocEntry', 0)
	const InstlmntID = get(location, 'state.data.InstlmntID', 0)
	const [commentsLoading, setCommentsLoading] = useState(false)
	const [comment, setComment] = useState('')
	const [comments, setComments] = useState([])
	const [employeeID, setEmployeeID] = useState(null)
	const [dollarLoading, setDollarLoading] = useState(false)

	const [isCommitLoading, setIsCommitLoading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [data, setData] = useState({})
	const [isComment, setIsComment] = useState({
		loading: false,
		order: -1,
	})
	const [dollar, setDollar] = useState(1)
	// const [deviceInfoData, setDeviceInfoData] = useState([])
	const [isLoading2, setIsLoading2] = useState(false)
	const [sumPay, setSumPay] = useState(0)
	const [payingSum, setPayingSum] = useState(0)
	const [tableData, setTableData] = useState([])
	const [tableData2, setTableData2] = useState([])
	const successModalRef = useRef()

	useEffect(() => {
		// Scroll to bottom when comments change
		if (commentRef.current) {
			commentRef.current.scrollTop = commentRef.current.scrollHeight
		}
	}, [comments])

	useEffect(() => {
		getAllData()
		getComments()
		getInformationOfItem()
	}, [])

	// const getInfoOFDevice = () => {
	//   setIsLoading2(true)
	//
	//   api
	//     .get(`Invoices(${DocEntry})`)
	//     .then((res) => {
	//       const resData = get(JSON.parse(res.data), 'DocumentLines[0]', [])
	//       setDeviceInfoData(resData)
	//       setIsLoading2(false)
	//     })
	//     .catch((err) => {
	//       errorRef.current?.open(
	//         get(JSON.parse(err.response.data), 'error.message', ''),
	//       )
	//     })
	// }

	// const getInformationOfItem = () => {
	// 	setIsLoading(true)
	// 	customFuncsApi
	// 		.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
	// 			headers: {
	// 				Prefer: 'odata.maxpagesize=50',
	// 			},
	// 		})
	// 		.then((res) => {
	// 			console.log('ok')
	// 			const resData = get(JSON.parse(res.data), 'value', [])
	// 			setTableData2(resData)
	// 			setTableData(resData)
	// 			setIsLoading(false)
	// 			let a = JSON.parse(JSON.stringify(resData))
	// 			let allSum = 0
	// 			let paySum = 0
	// 			const now = moment().format('YYYYMMDD')
	// 			for (let i = 0; i < a.length; i++) {
	// 				if (!moment(a[i].DueDate, 'YYYYMMDD').isAfter(now, 'day')) {
	// 					allSum = allSum + a[i].PaidToDate
	// 					paySum = paySum + a[i].InsTotal
	// 				}
	// 			}
	//
	// 			setSumPay(allSum)
	// 			setPayingSum(paySum)
	// 		})
	// 		.catch((err) => {
	// 			errorRef.current?.open(
	// 				get(JSON.parse(err.response.data), 'error.message', ''),
	// 			)
	// 		})
	// }

	const getInformationOfItem = () => {
		setIsLoading(true)
		customFuncsApi
			.get(`XsSql/getInstallments?docEntry=${DocEntry}`, {
				headers: {
					Prefer: 'odata.maxpagesize=50',
				},
			})
			.then((res) => {
				const resData = res.data.value || []
				setTableData2(resData)
				setTableData(resData)
				setIsLoading(false)
				let a = JSON.parse(JSON.stringify(resData))
				let allSum = 0
				let paySum = 0
				const now = moment().format('YYYYMMDD')
				for (let i = 0; i < a.length; i++) {
					if (!moment(a[i].DueDate, 'YYYYMMDD').isAfter(now, 'day')) {
						allSum = allSum + a[i].PaidToDate
						paySum = paySum + a[i].InsTotal
					}
				}
				setSumPay(allSum)
				setPayingSum(paySum)
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getAllData = async () => {
		await customFuncsApi
			.get(`installment?DocEntry=${DocEntry}&InstlmntID=${InstlmntID}`)
			.then((res) => {
				setData(get(res, 'data', {}))
				setEmployeeID(get(res, 'data.U_Dunner', null))
			})
			.catch((err) => {
				if (err?.response?.status === 401) {
					navigate('/login', { replace: true })
				}
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	const getComments = () => {
		setCommentsLoading(true)
		api
			.get(
				`U_COMMENTS?$filter=U_Invoice eq ${DocEntry}&$orderby=U_CreateTime asc, Code desc`,
				{
					headers: {
						Prefer: 'odata.maxpagesize=10000',
					},
				},
			)
			.then((res) => {
				setComments(get(JSON.parse(res.data), 'value', []))
			})
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally(() => {
				setCommentsLoading(false)
			})
	}

	const deleteComments = (id, order) => {
		setIsComment((prevState) => ({ ...prevState, loading: true, order }))
		api
			.delete(`U_COMMENTS(${id})`)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
			.finally((res) => {
				setIsComment((prevState) => ({
					...prevState,
					loading: false,
					order: -1,
				}))
			})
	}

	const sendComment = () => {
		setComment('')
		const date = moment().tz('Etc/GMT-5').format('YYYY-MM-DDTHH:mm:ssZ')

		const body = {
			U_Dunner: get(getMe, 'EmployeeID', ''),
			U_CreateDate: date,
			U_Invoice: get(data, 'DocEntry', ''),
			U_InstlmntID: InstlmntID,
			U_Text: comment,
			U_CreateTime: date,
		}
		api
			.post('U_COMMENTS', body)
			.then((res) => getComments())
			.catch((err) => {
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	// const createPayment = (sum, typeOfPayment, valuta, courseDollar) => {
	//   setIsLoading(true)
	//
	//   api
	//     .post(`IncomingPayments`, {
	//       CardCode: get(data, 'CardCode', 0),
	//       CashSum: Number(sum),
	//       CashAccount: getMe[typeOfPayment],
	//       BankChargeAmount: 0,
	//       DocCurrency: valuta,
	//       PaymentInvoices: [
	//         {
	//           DocEntry,
	//           InstallmentId: get(data, 'InstlmntID', 0),
	//           SumApplied:
	//             valuta === 'UZS'
	//               ? Number(sum) / Number(courseDollar)
	//               : Number(sum),
	//         },
	//       ],
	//     })
	//     .then(() => {
	//       successRef.current?.open('Платеж успешно создан')
	//       setIsLoading(false)
	//     })
	//     .catch((err) => {
	//       setIsLoading(false)
	//       errorRef.current?.open(
	//         get(JSON.parse(err.response.data), 'error.message', ''),
	//       )
	//     })
	// }

	const getUSD = () => {
		setDollarLoading(true)
		api
			.post(`SBOBobService_GetCurrencyRate`, {
				Currency: 'UZS',
				Date: moment(),
			})
			.then((res) => {
				setDollar(JSON.parse(res.data))
			})
			.catch((err) => {
				setDollar('error')
			})
			.finally(() => setDollarLoading(false))
	}
	useEffect(() => {
		getUSD()
	}, [])
	const createPayment = ({
		DocDate,
		CurrencyRate,
		AmountUsd,
		AmountUzs,
		AmountPayme,
		NumberCard,
		Terminal,
		UsdCard,
	}) => {
		setIsLoading(true)
		const payments = []
		if (AmountUsd) {
			payments.push({
				usdSum: AmountUsd,
				account: UsdCard || getMe.U_CashAccount,
				currency: 'USD',
				uzsSum: null,
			})
		}
		if (AmountUzs) {
			payments.push({
				usdSum: AmountUzs / CurrencyRate,
				account: getMe.U_CashUzsAccount,
				currency: 'UZS',
				uzsSum: AmountUzs,
			})
		}
		if (AmountPayme) {
			payments.push({
				usdSum: AmountPayme / CurrencyRate,
				account: NumberCard || getMe.U_CardAccount,
				currency: 'UZS',
				uzsSum: AmountPayme,
			})
		}
		if (Terminal) {
			payments.push({
				usdSum: Terminal / CurrencyRate,
				account: getMe.U_TerminalAccount,
				currency: 'UZS',
				uzsSum: Terminal,
			})
		}
		customFuncsApi
			.post('addIncomingPayments', {
				DocDate,
				DocEntry: DocEntry,
				CardCode: get(data, 'CardCode', null),
				DocRate: CurrencyRate,
				payments: payments,
			})
			.then((err) => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'post',
						path: `/addIncomingPayments`,
						description: 'bitta undiruvga kirganda tolov qilish',
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify({
							DocDate,
							DocEntry: DocEntry,
							CardCode: get(data, 'CardCode', null),
							DocRate: CurrencyRate,
							payments: payments,
						}),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				paymentRef.current?.close()
				successModalRef.current?.open('Платеж успешно произведен!')
				getInformationOfItem()
			})
			.catch((err) => {
				paymentRef.current?.close()
				errorRef.current?.open(JSON.stringify(err.response.data))
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const cancel = () => {
		navigate('/recovery')
	}

	const allSaves = () => {
		setIsCommitLoading(true)
		api
			.patch(`Invoices(${get(data, 'DocEntry', 0)})`, {
				U_Dunner: employeeID,
			})
			.then(() => {
				try {
					logsUrl.post('apis', {
						company_name: COMPANY_NAME,
						method: 'patch',
						path: `/Invoices(${get(data, 'DocEntry', 0)})`,
						description: 'bitta undiruvga kirganda saqlash bosilganda',
						username: `${getMe.FirstName} ${getMe.LastName}, EmployeeID: ${getMe.EmployeeID}`,
						body: JSON.stringify({
							U_Dunner: employeeID,
						}),
					})
				} catch (err) {
					errorRef.current?.open(
						'Bu jarayon logs tarixiga saqlanmadi. Iltimos bu haqida bizga habar bering!',
					)
				}
				successRef.current?.open('Заказ успешно сохранен')
				setIsCommitLoading(false)
			})
			.catch((err) => {
				setIsCommitLoading(false)
				errorRef.current?.open(
					get(JSON.parse(err.response.data), 'error.message', ''),
				)
			})
	}

	return (
		<Layout>
			<ViewRecoveryItemStyle>
				<div className="flex gap-2 flex-col md:flex-row flex-1">
					<div className="container shadow-lg  p-2 border-[1px] border-[#DFE2E8] flex gap-4 flex-col xl:flex-row">
						<div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 w-full h-fit">
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Код</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'DocNum', 'Kod')}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>ФИО</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'CardName', 'Покупатель')}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Продукт
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'Dscription', 'Товар')}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>Тел.</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'U_Telephone', 'Tel')}
									disabled={true}
								/>
							</div>
							{/*<div>*/}
							{/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
							{/*    Оплачено за этот месяц*/}
							{/*  </p>*/}
							{/*  <input*/}
							{/*    type="text"*/}
							{/*    className={*/}
							{/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
							{/*    }*/}
							{/*    value={roundCurrency(get(data, 'INV6.PaidToDate', '0'))}*/}
							{/*    disabled={true}*/}
							{/*  />*/}
							{/*</div>*/}

							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Общий долг клиента
								</p>
								<input
									type="text"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={roundCurrency(
										+get(data, 'DocTotal', '0') - +get(data, 'PaidToDate', '0'),
									)}
									disabled={true}
								/>
							</div>
							{/*<div>*/}
							{/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>Цена</p>*/}
							{/*  <input*/}
							{/*    type="number"*/}
							{/*    placeholder="Текущая цена"*/}
							{/*    className={*/}
							{/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
							{/*    }*/}
							{/*    value={roundCurrency(*/}
							{/*      get(data, 'DocTotal', 'Текущая цена не найден'),*/}
							{/*    )}*/}
							{/*    disabled={true}*/}
							{/*  />*/}
							{/*</div>*/}
							{/*<div>*/}
							{/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
							{/*    Заплатил за эту покупку*/}
							{/*  </p>*/}
							{/*  <input*/}
							{/*    type="number"*/}
							{/*    placeholder="Цена"*/}
							{/*    className={*/}
							{/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
							{/*    }*/}
							{/*    value={roundCurrency(*/}
							{/*      get(data, 'PaidToDate', 'Цена не найден'),*/}
							{/*    )}*/}
							{/*    disabled={true}*/}
							{/*  />*/}
							{/*</div>*/}
							{/*<div>*/}
							{/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
							{/*    Должен заплатить в этом месяце*/}
							{/*  </p>*/}
							{/*  <input*/}
							{/*    type="number"*/}
							{/*    placeholder="Цена"*/}
							{/*    className={*/}
							{/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
							{/*    }*/}
							{/*    value={roundCurrency(get(data, 'InsTotal', 'Цена не найден'))}*/}
							{/*    disabled={true}*/}
							{/*  />*/}
							{/*</div>*/}
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>ИМEИ</p>
								<input
									placeholder="IntrSerial"
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'IntrSerial', 'IntrSerial не найден')}
									disabled={true}
								/>
							</div>
							<div>
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Icloud
								</p>
								<input
									className={
										'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
									}
									value={get(data, 'U_Icloud', '-')}
									disabled={true}
								/>
							</div>
							<div>
								{/*<p className={"comment"}>Комментарии</p>*/}
								<p className={'font-medium text-zinc-600 text-sm mb-1'}>
									Комментарий
								</p>
								<div className={'commentSection'}>
									<textarea
										name="comment"
										// id="comment"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										// cols="30"
										rows="1"
										value={comment}
										onChange={(v) => setComment(v.target.value)}
									/>
									<button
										className={'sendComment'}
										onClick={() => sendComment()}
										disabled={isEmpty(comment)}
									>
										<AiOutlineSend />
									</button>
								</div>
							</div>
							{/*<div>*/}
							{/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
							{/*    Дата платежа*/}
							{/*  </p>*/}
							{/*  <input*/}
							{/*    type="string"*/}
							{/*    placeholder="Цена"*/}
							{/*    className={*/}
							{/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
							{/*    }*/}
							{/*    defaultValue={get(data, 'DueDate', 'Sana').slice(0, 10)}*/}
							{/*    disabled={true}*/}
							{/*  />*/}
							{/*</div>*/}
							{/*<div>*/}
							{/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
							{/*    Количество месяцев*/}
							{/*  </p>*/}
							{/*  <input*/}
							{/*    type="number"*/}
							{/*    placeholder="Цена"*/}
							{/*    className={*/}
							{/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
							{/*    }*/}
							{/*    value={get(data, 'Installmnt', 1) - 1}*/}
							{/*    disabled={true}*/}
							{/*  />*/}
							{/*</div>*/}
							{/*<div>*/}
							{/*  <p className={'font-medium text-zinc-600 text-sm mb-1'}>*/}
							{/*    Текущий месяц*/}
							{/*  </p>*/}
							{/*  <input*/}
							{/*    type="number"*/}
							{/*    placeholder="Цена"*/}
							{/*    className={*/}
							{/*      'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'*/}
							{/*    }*/}
							{/*    value={roundCurrency(get(data, 'InstlmntID', 1)) - 1}*/}
							{/*    disabled={true}*/}
							{/*  />*/}
							{/*</div>*/}
							{get(getMe, 'Department2.Name', '') !== 'Undiruv' ? (
								<div>
									<p className={'font-medium text-zinc-600 text-sm mb-1'}>
										Сменить приёмщик
									</p>
									<select
										name="executer"
										id="executer"
										className={
											'border-[1px] border-[#DFE2E9"] p-2 rounded-md w-full'
										}
										onChange={(v) => {
											setEmployeeID(v.target.value)
										}}
										defaultValue={'s'}
									>
										{executer.map((v, i) => {
											return (
												<option
													value={get(v, 'EmployeesInfo.EmployeeID', 0)}
													key={i}
													selected={
														v.EmployeesInfo.EmployeeID ===
														get(data, 'U_Dunner', '')
													}
												>
													{get(v, 'EmployeesInfo.FirstName', 'Name')}{' '}
													{get(v, 'EmployeesInfo.LastName', 'Surename')}
												</option>
											)
										})}
									</select>
								</div>
							) : null}
							<div className="flex gap-3 flex-col lg:flex-row justify-end w-full lg:col-span-2 sm:col-span-2">
								<div className="flex gap-3">
									<Button
										btnStyle={{ backgroundColor: 'red' }}
										onClick={cancel}
									>
										Назад
									</Button>
								</div>
								<Button
									btnStyle={{ width: 250 }}
									hoverBtnStyle={{ width: 250 }}
									onClick={allSaves}
									isLoading={isCommitLoading}
								>
									Сохранять
								</Button>
							</div>
						</div>
						<div>
							<div className="flex flex-col sm:flex-row gap-2 sm:gap-6">
								<p>
									<span className="font-bold text-zinc-900">Курс:</span>{' '}
									{numberWithSpaces(dollar)} UZS
								</p>
								<p>
									<span className="font-bold text-zinc-900">Долг:</span>{' '}
									<span className="debt">
										-{(+payingSum - +sumPay).toFixed(2)}
									</span>{' '}
									USD
								</p>
							</div>
							<div className="center py-5">
								{get(getMe, 'Department2.Name', '') === 'Boshqaruv' ||
								get(getMe, 'Department2.Name', '') === 'Undiruvchi1' ||
								get(getMe, 'Department2.Name', '') === 'Sherik' ? (
									<Button
										btnStyle={{ backgroundColor: '#243AB5' }}
										onClick={() => paymentRef.current?.open()}
										isLoading={isLoading || isLoading2 || dollarLoading}
										hoverBtnStyle={{ backgroundColor: '#243AB5' }}
									>
										Оплатить
									</Button>
								) : null}
							</div>

							<div className="overflow-y-auto mb-8">
								<table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
									<thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
										<tr>
											<th scope="col" className="px-6 py-4">
												N
											</th>
											<th scope="col" className="px-6 py-4">
												Дата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплата
											</th>
											<th scope="col" className="px-6 py-4">
												Оплачено
											</th>
										</tr>
									</thead>
									<tbody>
										{tableData.map((v, i) => {
											let a = v.DueDate.split('')
											a.splice(4, 0, '-')
											a.splice(7, 0, '-')
											a.join()
											return (
												<tr
													className="bg-white border-b  hover:bg-gray-50 "
													key={i}
												>
													<td className="px-6 py-4 font-medium text-gray-900 ">
														{i}
													</td>
													<td className="px-6 py-4 whitespace-nowrap">{a}</td>
													<td className="px-6 py-4">
														{Number(v.InsTotal).toFixed(2)}
													</td>
													<td className="px-6 py-4">
														{Number(v.PaidToDate).toFixed(2)}
													</td>
												</tr>
											)
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="shadow-md w-full  md:min-w-[300px] md:max-w-[350px] border-[1px] border-[#DFE2E8]">
						<div className="p-4 shadow-md">
							Комментарии{' '}
							{commentsLoading.loading && (
								<ClipLoader
									loading={true}
									size={10}
									aria-label="Loading Spinner"
									data-testid="loader"
								/>
							)}
						</div>
						<div ref={commentRef} className="p-4 h-[85vh] overflow-y-scroll">
							{comments.map((v, i) => {
								return (
									<div className="mb-4">
										<p className={'font-medium text-zinc-400 text-sm mb-1'}>
											{moment(get(v, 'U_CreateTime', '')).format(
												'DD-MM-YYYY HH:mm',
											)}
											{isComment.loading && isComment.order === i && (
												<ClipLoader
													loading={true}
													size={10}
													aria-label="Loading Spinner"
													data-testid="loader"
												/>
											)}
										</p>
										<div className="inputCard2">
											<div className="p-3 pt-5 font-medium rounded-lg rounded-bl-none bg-[#29d2f5] text-white relative">
												<button
													className="absolute top-1 right-1 z-10 cursor-pointer"
													onClick={() => deleteComments(get(v, 'Code', ''), i)}
												>
													<CloseIcon className="text-white w-[20px] " />
												</button>
												{get(v, 'U_Text', '')}
											</div>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</ViewRecoveryItemStyle>
			<>
				{/*<PaymentModal*/}
				{/*  getRef={(r) => {*/}
				{/*    paymentRef.current = r*/}
				{/*  }}*/}
				{/*  onConfirm={(sum, typeOfPayment, valuta, courseDollar) =>*/}
				{/*    createPayment(sum, typeOfPayment, valuta, courseDollar)*/}
				{/*  }*/}
				{/*/>*/}
				{dollarLoading ? null : (
					<PaymentModal
						getRef={(r) => (paymentRef.current = r)}
						onConfirm={(form) => createPayment(form)}
						onClose={() => paymentRef.current?.close()}
						loading={isLoading}
						currencyRate={dollar}
					/>
				)}
				<ErrorModal getRef={(r) => (errorRef.current = r)} />
				<SuccessModal
					getRef={(r) => {
						successRef.current = r
					}}
				/>
				<SuccessModal
					getRef={(r) => (successModalRef.current = r)}
					onConfirm={() => successModalRef.current?.close()}
				/>
			</>
		</Layout>
	)
}

export default ViewRecoveryItem
